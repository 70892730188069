<!-- eslint-disable -->
<template>
  <div class="page_box">
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <el-form ref="form" :model="formData" :rules="formRules" label-width="120px">
      <div class="module_item">
        <div class="form_box">
          <el-form-item class="form_item" label="选择渠道：" prop="locationChannel">
            <el-select v-model="formData.locationChannel" placeholder="请选择" clearable @change="selectChange">
              <el-option v-for="(item,index) in channelList" :key="index" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form_item" label="选择页面：" prop="locationType">
            <el-select v-model="formData.locationType" placeholder="请选择" clearable @change="selectChange">
              <el-option v-for="(item,index) in pageList" :key="index" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form_item" label="选择坑位：" prop="locationNo">
            <el-select v-model="formData.locationNo" placeholder="请选择" @change="locationChange" @visible-change="selectPitChange" clearable>
              <el-option v-for="(item,index) in pitList" :key="index" :label="item.name" :value="item.locationNo"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form_item" label="子标题坑位：" prop="locationSubNo" v-if="locationList.length>0">
            <el-select v-model="formData.locationSubNo" placeholder="请选择" clearable @change="subLocationChange">
              <el-option v-for="(item,index) in locationList" :key="index" :label="item.subType" :value="item.locationNo"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item class="form_item" label="内容类型：" prop="activityName">
            <el-select v-model="formData.activityName" placeholder="请选择" clearable>
              <el-option label="已启用" :value="1"></el-option>
              <el-option label="已停用" :value="0"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item class="form_item" label="内容ID：" prop="contentNo">
            <el-input v-model="formData.contentNo"></el-input>
          </el-form-item>
          <el-form-item v-if="locationData.imageSwitch==1" label="封面图：" prop="image" class="form_item">
            <file-upload
              v-model="formData.image"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="coverImgList"
              :maxNum="1"
              width="72px"
              height="72px"
              @uploadSuccess="coverImgListUploadSuccess"
            ></file-upload>
          </el-form-item>
          <el-form-item class="form_item" label="地区：" prop="scopeType">
            <el-radio-group v-model="formData.scopeType">
              <el-radio v-for="(item,index) in scopeTypeList" :key="index" :label="item.value">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="formData.scopeType !=1" class="form_item" label="地区：" prop="areaList">
            <el-cascader 
              @change="citySelectChange" 
              v-model="citySelect" 
              :props="props" 
              :placeholder="citySelectName?citySelectName:'请选择'" 
              style="width: 320px;"
              popper-class="disableFirstLevel">
            </el-cascader>
          </el-form-item>
          <el-form-item v-if="locationData.titleSwitch==1" class="form_item" label="标题：" prop="title">
            <el-input v-model="formData.title"></el-input>
          </el-form-item>
          <el-form-item v-if="locationData.subTitleSwitch==1" class="form_item" label="副标题：" prop="subTitle">
            <el-input v-model="formData.subTitle"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="排序：" prop="">
            <el-input v-model="formData.sort"></el-input>
          </el-form-item>
          <el-form-item class="form_item" label="背景色值：" prop="backgroundColor">
            <el-input v-model="formData.backgroundColor"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="form_btn">
      <el-button @click="cancelFn">取消</el-button>
      <el-button type="primary" @click="saveForm">保存</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import t from "@common/src/i18n/t";
import {namespace} from "vuex-class";
const marketing = namespace("marketing");

import { emptyToLine, splitThousands } from "@/utils/common";
import FileUpload from "@/components/scope/file-upload.vue";
import { getImageSize } from "@/utils/common";
export default {
  // emptyToLine = emptyToLine;
  // splitThousands = splitThousands;
  //import引入的组件需要注入到对象中才能使用
  // @marketing.Action queryCardTemplateList;
  // @management.Action getStoreList
  // @management.Action getStoreCouponList

  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        isAdd: null, //是否为添加
        addType: '',                                //新建类型 1-新建链接 2-新建图文 3-新建视频
        formData: {
          locationChannel: '',                        //渠道
          locationType: '',                           //页面
          locationNo: '',                     //坑位
          locationSubNo: '',                  //子坑位
          contentNo: '',                      //内容编号
          image: '',                          //封面图
          title: '',                          //标题
          subTitle: '',                       //副标题
          sort: '',                           //排序
          backgroundColor: '',                //背景色
          stateCode: '',                //省
          cityCode: '',                 //市
          districtCode: '',             //区
          areaList: [],
          scopeType: 1,
        },
        formRules: {
          locationSubNo: [
            { required: true, message: '请选择子坑位', trigger: 'blur' },
          ],
          locationChannel: [
            { required: true, message: '请选择渠道', trigger: 'change' },
          ],
          locationType: [
            { required: true, message: '请选择页面', trigger: 'change' },
          ],
          locationNo: [
            { required: true, message: '请选择坑位', trigger: 'change' },
          ],
          contentNo: [
            { required: true, message: '请输入内容编号', trigger: 'blur' },
          ],                      //内容编号
          image: [
            { required: true, message: '请上传封面图', trigger: 'change' },
          ],                          //封面图
          title: [
            { required: true, message: '请输入标题', trigger: 'blur' },
          ],                          //标题
          subTitle: [
            { required: true, message: '请输入副标题', trigger: 'blur' },
          ],                       //副标题
          sort: [
            { required: true, message: '请输入排序', trigger: 'blur' },
          ],    
          areaList: [
            { required: true, message: '请选择地区', trigger: 'change' },
          ],                        
        },
        option: {
          height: 500
        },
        html: '<p></p>',
        coverImgList: [],
        channelList: [],         //渠道列表
        pageList: [],            //页面列表
        pitList: [],             //坑位列表
        citySelect: [],          //已选地区
        locationList: [],        //子标题坑位
        locationData: {},        //当前已选坑位
        scopeTypeList: [{name: "全国", value: 1}, {name: "部分城市", value: 2}],
        detailInfo: {},
        citySelectName: [],
        props: {
          lazy: true,
          multiple: true,
          lazyLoad: (node, resolve) => {
            const { level,value } = node;
            console.log(level)
            this.$store.dispatch('base/getAreaList',{
              level: level+1,
              parentAreaCode: value
            }).then(res => {
              let nodes = res.data.list.map(item => {
                item.value = item.areaCode;
                item.label = item.areaName;
                item.leaf = level >= 2;
                return item
              })
              if(level ==2){
                if(this.detailInfo.areaList&&this.detailInfo.areaList.length>0){
                  // this.next = true;
                  let a = [];
                  this.detailInfo.areaList.forEach(item => {
                    a = [item.stateCode,item.cityCode,item.districtCode]
                    this.citySelect.push(a)
                  })
                  console.log(this.citySelect)
                }
              }
              resolve(nodes);
            })
            
          }
        }
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "渠道&坑位管理", path: "/management/content/contentDistribution/list" },
        {
          name: this.isAdd ? "新建分发" :"修改分发"
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 渠道or页面选择
    selectChange(e){
      this.pitList = [];
      this.formData.locationSubNo = '';
      this.formData.locationNo = '';
      if(this.formData.locationChannel&&this.formData.locationType){
        this.getPagePit();
      }
    },
    // 坑位选择提示
    selectPitChange(e){
      if(!e) return;
      if(!this.formData.locationChannel){
        this.$message.warning('请先选择渠道');
        return;
      }
      if(!this.formData.locationType){
        this.$message.warning('请先选择页面');
        return
      }
    },
    // 坑位选择
    locationChange(e,locationSubNo=''){
      this.formData.locationSubNo = '';
      let pitList = this.pitList;
      let locationList = [];
      pitList.forEach(item => {
        if(e == item.locationNo){
          locationList = item.child;
          this.locationData = item
        }
      })
      this.locationList = locationList;
      this.formData.locationSubNo = locationSubNo;
    },
    // 子坑位选择
    subLocationChange(e){
      const locationList = this.locationList;
      locationList.forEach(item => {
        if(e == item.locationNo){
          this.locationData = item
        }
      })
    },
    // 地区选择
    citySelectChange(e){
      this.formData.areaList = [];
      e.forEach(item => {
        this.formData.areaList.push({stateCode: item[0], cityCode: item[1], districtCode: item[2]});
      })
    },
    // 封面图上传
    coverImgListUploadSuccess(url) {
      this.$refs.form.validateField("coverImg");
      this.formData.coverImgList = url;
    },
    
    // 表单保存
    saveForm(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch('management/distributeAdd',{
            ...this.formData,
            distributeNo: this.$route.query.distributeNo || ''
          }).then(res => {
            this.$message.success('保存成功！')
            this.$router.back()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancelFn(){
      this.$router.back();
    },

    // 获取channel数据
    getChannelList(){
      this.$store.dispatch('management/getContentChannelList',{}).then(res => {
        this.channelList = res.data;
      })
    },
    // 获取页面数据
    getPageList(){
      this.$store.dispatch('management/getContentPageList',{}).then(res => {
        this.pageList = res.data;
      })
    },
    // 获取坑位
    getPagePit(){
      this.$store.dispatch('management/contentModuleLocation',{
        channel: this.formData.locationChannel,
        type: this.formData.locationType
      }).then(res => {
        this.pitList = res.data;
        if (this.$route.query.distributeNo) {
          this.locationChange(this.formData.locationNo,this.formData.locationSubNo)
        }
      })
    },
    getDetail(distributeNo){
      this.$store.dispatch('management/distributeDetail',{
        distributeNo
      }).then(res => {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = res.data[key];
        })
        this.detailInfo = res.data;
        this.coverImgList = [{url: res.data.image}]
        if(this.detailInfo.areaList.length>0){
          let a = [];
          this.citySelectName = [];
          this.detailInfo.areaList.forEach(item => {
            a = [item.stateName+'/'+item.cityName+'/'+item.districtName]
            this.citySelectName.push(a)
          })
          console.log(this.citySelectName)
        }
        // 获取坑位
        this.getPagePit();
        console.log(this.citySelect)
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 获取channel数据
    this.getChannelList();
    // 获取页面数据
    this.getPageList();
    // 获取当前路由的名称
    this.isAdd = this.$route.name === "contentDistributionAdd";
    this.pageName = this.$route.name;
    if (this.$route.query.distributeNo) {
      this.getDetail(this.$route.query.distributeNo);
    }

  },

  beforeRouteEnter (to, from, next) {
    // `from` 是跳转前的路由对象
    console.log('From:', from);
    next();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss">
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;
    .module_item{
      background: #fff;
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-top: 15px;
      .module_handle{
        display: flex;
        align-items: center;
        >div:nth-child(2){
          width: 1px;
          height: 16px;
          background: #000;
          margin: 0 10px;
        }
      }
      .module_up{
        width: 100%;
        display: flex;
        align-items: center;
        >.form_item{
          width: 40%;
        }
      }
      .module_radio{
        width: 100%;
        display: flex;
        align-items: center;
        >.form_item{
          width: 40%;
          flex-shrink: 0;
        }
        >.el-input{
          width: 200px;
          margin-left: 20px;
        }
      }
      .title{
        font-weight: bold;
      }
      .select_dialog{
        color: #ff9500;
        padding: 0;
      }
      .form_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        .form_item{
          width: 40%;
          flex-shrink: 0;
          box-sizing: border-box;
          .el-input{
            width: 80%;
          }
        }
        .form_item_price{
          display: flex;
          justify-content: flex-start;
          .el-input{
            width: 100px;
          }
          span{
            display: inline-block;
            margin-left: 8px;
          }
        }
      }
    }
    .form_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding-bottom: 20px;
    }
  }
  // ::v-deep .form_item {
  //   input::-webkit-outer-spin-button,
  //   input::-webkit-inner-spin-button {
  //     -webkit-appearance: none;
  //   }
  //   input[type="number"] {
  //     -moz-appearance: textfield;
  //   }
  //   inpit {
  //     border: none
  //   }
  // }

  // ::v-deep .el-table__header-wrapper .el-checkbox {
	//   display: none;
	// }

  .dialog_box{
    .search_store{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-input{
        width: 300px;
      }
    }
    .page_pagination{
      text-align: center;
      margin-top: 20px;
    }
    .dialog_btn{
      text-align: end;
    }
  }
  .table_form{
    display: flex;
    align-items: center;
  }
.disableFirstLevel{
  .el-cascader-panel {
    .el-scrollbar:first-child {
      .el-checkbox {
        display: none;
      }
    }
    .el-scrollbar:nth-child(2) {
      .el-checkbox {
        display: none;
      }
    }
  }
}
</style>
